.keyValue {
  justify-content: space-between;
  align-items: center;
  background-color: var(--colors-gray-80);
  padding: 4px 4px 4px 12px;
  border-radius: 8px;
  user-select: all;
  position: relative;
}

@keyframes moveUpAndFade {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  70% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.copyHint {
  animation: moveUpAndFade 1.5s ease-in-out;
  animation-fill-mode: forwards;
  position: absolute;
  top: -40px;
  right: 0;
  font-weight: bold;
  color: white;
  user-select: none;
  background-color: var(--colors-gray-700);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  align-items: center;
  gap: 4px;
}
