.usersContainer {
    border-radius: 8px;
}

.tableTitle, .scimHeaderTitle {
    color: var(--colors-neutral-400);
}

.tableTitle {
    margin-bottom: 12px;
}