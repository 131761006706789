.detailsCtn {
  flex: 1;
  flex-direction: column;
  gap: 32px;
  min-width: 0;
}

.header {
  border-radius: 0.5rem;
  position: sticky;
  top: 40px;
  background-color: white;
  z-index: 100;

  [role='tablist'] {
    [role='tab'] {
      padding: 8px 24px;
    }
  }
}

.cardCtn {
  overflow-y: auto;
}

.heading {
  button {
    gap: 4px;
  }
}

.reviewPanel {
  width: 50%;
  animation: slideIn 0.2s ease;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
