.parentDetailsTitle {
  margin: 0.5rem 0;
}

.parentDetailsWrapper {
  display: flex;
  flex-flow: column;
  padding: 1rem;
  gap: 1rem;

  & > div {
    display: flex;
    flex-flow: row;
    gap: 1rem;

    p:first-of-type {
      display: flex;
      align-items: center;
      width: 15%;
      min-width: 120px;
      font-weight: 500;
    }

    p:nth-of-type(2) {
      width: auto;
      color: var(--colors-gray-600);
    }

    a {
      display: inline-flex;
      align-items: center;
      line-height: 1rem;
      color: var(--colors-primary-400);

      span {
        border-bottom: 1px solid var(--colors-primary-400);
      }

      svg {
        margin-left: 0.25rem;
      }
    }
  }
}

.ownerWrapper {
  display: flex;
  flex-direction: column;

  .email {
    font-size: 10px;
    word-break: break-word;
  }
}