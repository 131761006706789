.link {
  color:  var(--colors-primary-400);
  border-bottom: 1px solid var(--colors-primary-400);
}

.searchAndFilters {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.ownerWrapper {
  display: flex;
  flex-direction: column;

  .email {
    font-size: 10px;
    word-break: break-word;
  }
}