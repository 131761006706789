.questionText {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.scoreButton[type='button'] {
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 1px solid #f1f0f2;
  height: 36px;

  &:hover {
    border-color: #8b4aff;
  }

  :first-child {
    text-decoration: none;
  }
}

.active[type='button'] {
  color: #8b4aff;
  border-color: #8b4aff;
}

.scoreScaleDescription {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #6d6a73;
}
