.fundraisersNameCell {
  color: var(--colors-primary-400);
  border-bottom: 1px solid var(--colors-primary-400);
}

.donorWrapper {
  display: flex;
  flex-direction: column;

  .email {
    font-size: 10px;
    word-break: break-word;
  }
}

.filterContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.fundraiserGoalCell {
  word-break: break-word;
}
