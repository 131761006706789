.questionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-top: 1px solid #f1f0f2;
  margin-top: -12px;
  padding-top: 16px;
  margin-bottom: 70px;
}

.card {
  box-shadow: 0px 40px 72px 0px #21253529;
  position: sticky;
  top: 40px;
  // Height - spacing - header
  max-height: calc(100vh - 40px - 70px);
  overflow-y: auto;
}
