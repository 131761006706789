.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.buttonsWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.5rem;
}
