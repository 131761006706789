.cellWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.disabled {
  color: var(--colors-gray-600);
}

.selectCell {
  width: 20px;
  :hover {
    background-color: inherit;
  }
}