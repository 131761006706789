.bulkWalletWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
}

.uploadDescription {
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 1.5;

  a {
    color: var(--colors-primary-400);
    text-decoration: underline;
    text-underline-position: under;
  }
}

.uploadList {
  font-size: 14px;
  margin-left: 0px;
  line-height: 1.5;
}

.uploadList li {
  margin-bottom: 8px;
}

.bulkWalletContent {
  gap: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.bulkWalletTable > div:first-child {
  height: 400px;
  padding: 0px !important;
}
