.header {
  border-radius: 0.5rem;
  background-color: white;

  [role='tablist'] {
    [role='tab'] {
      padding: 8px 24px;
    }
  }
}

.heading {
  button {
    gap: 4px;
  }
}
